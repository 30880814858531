import { DELETE_LOCAL_DB_DATA, GET_LOCAL_DB_DATA, GET_USER_BUSINESS_DATA, SAVE_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "../../eventBus";
export default {
    data() {
        return {
            proceed: false,
            payload: {
                username: '',
                password: '',
            },
            showPasswordModal: false,
            showPNDModal: false,
            temporaryPayload: null,
            temporaryPin: null,
            showWrongPinWarning: false,
            wrongPinWarning: {
                code: '',
                message: '',
                blocked: false
            },
            timer: null
        }
    },
    methods: {
        holdTemporaryPayloadForRetry(temporaryPayload = null, pin = null) {
            // debugger
            this.temporaryPayload = temporaryPayload
            this.showPasswordModal = true
            this.temporaryPin = pin
        },
        isPNDActivated(response = null) {
            if(response && response.message === 'PND Activated - Please Contact Traction') {
                this.showPNDModal = true;
                return true;
            }
        },
        hasLocalWalletToken() {
            const WalletToken = GET_LOCAL_DB_DATA('wallet_token');
            return WalletToken ? true : false;
        },
        checkIncorrectPin(response = null) {
            if (response && response.code) {
                const list = ['WAL002', 'WAL001']
                if (list.includes(response.code)) {
                    this.showWrongPinWarning = true
                    this.wrongPinWarning.code = response.code
                    this.wrongPinWarning.message = response.message
                    this.wrongPinWarning.blocked = response.code === 'WAL001' ? true : false
                } else {
                    this.showWrongPinWarning = false
                }
            }
        },
        verifyWalletToken() {
            return this.handlePromise(this.$store.dispatch("WALLET_VERIFY_TOKEN", { token: GET_LOCAL_DB_DATA('wallet_token') }))
        },
        async handlePromise(suppliedPromise) {
            try {
                const data = await suppliedPromise
                return [data, null]
            } catch (error) {
                return [null, error]
            }
        },
        async verifyThenProcess() {
            this.payload.username = GET_USER_BUSINESS_DATA().email
            const res = await this.$store.dispatch("WALLET_AUTH", {
                email: this.payload.username,
                password: this.payload.password,
            });
            if (res.status) {
                    SAVE_LOCAL_DB_DATA("wallet_token", res.data);
                    this.showPasswordModal = false;
                    Promise.resolve()
            } else {
                eventBus.$emit("trac-alert", {
                    message: res.message || "Invalid password.",
                });
                Promise.resolve()
            }
        },
        goToWalletAuth(option = { deleteToken: false }) {
            if (option.deleteToken) {
                DELETE_LOCAL_DB_DATA('wallet_token');
            }
            this.$router.push({ name: 'MainWalletLogin' });
        },
        lockOutAfterInActivity(timeinMicroseconds = 900000) {
            // after 15 minutes, fire this.goToWalletAuth()
            const timeOut = timeinMicroseconds
            this.timer = setTimeout(() => {
                DELETE_LOCAL_DB_DATA('wallet_token');
                this.goToWalletAuth();
                clearTimeout(this.timer);
            }, timeOut);
        },
        verifyWalletAuth() {
            // run a dispatch for 'WALLET_AUTH' action and if res.status is true, set proceed to true
            this.$store.dispatch("WALLET_AUTH", this.payload).then((res) => {
                if (res.status) {
                    this.proceed = true;
                } else {
                    eventBus.$emit("trac-alert", {
                        message: res.message,
                    });
                    this.proceed = false;
                }
            });
        },
    },
    computed: {
        formValid() {
            return {
                username: this.payload.username.length > 0,
                password: this.payload.password.length > 0,
            }
        }
    },
    destroyed () {
        clearTimeout(this.timer);
    },
}