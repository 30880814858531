<template>
  <main class="mr-4">
    <div class="mx-auto md:w-9/12">
      <trac-back-button>Security</trac-back-button>
      <trac-loading v-if="isLoading" />
      <h3 class="my-6 font-bold text-sm">Your Devices</h3>
    </div>

    <div
      class="shadow-lg bg-white rounded-md p-4 md:w-9/12 mx-auto my-8 flex flex-col items-center"
    >
      <div class="grid grid-cols-3 w-full gap-3">
        <!-- Computer Side -->
        <div v-if="computerDevices.length > 0" class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/tv.svg" alt="tv" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Web</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{computerDevices.length}} Device<span v-if="computerListPlural">s</span></p>
            </div>
          </div>
          <div v-for="(device, index) in computerDevices" :key="index" class="flex flex-col gap-y-1 mb-2 border-b border-gray-500 pb-3">
            <div class="tiny font-semibold">{{device.deviceModel}}</div>
            <div class="tiny">
              <span class="inline-block active-gray flex-initial p-1 rounded">
                <div class="flex items-center gap-x-1">
                  <div v-if="device.session.loggedIn" class="w-3 h-3 bg-green-500 rounded-full"></div>
                  <span v-if="device.session.loggedIn">Currently signed in</span>
                  <span v-else>{{processTimestamp(device.session.activityTimestamp)}}</span>
                </div>
              </span>
            </div>
            <div class="tiny">{{device.browser.name}}</div>
            <div class="flex justify-between items-center">
              <!-- <div v-if="device.whitelisted" class="tiny flex-initial bg-green-500 text-white p-1 rounded">Whitelisted</div> -->
              <!-- <div v-else class="tiny flex-initial bg-gray-300 text-gray-700 p-1 rounded">Not Whitelisted</div> -->
              <div class="flex-1"></div>
              <div @click="signOutDevice(device.deviceId)" class="tiny cursor-pointer flex-initial border border-red-400 text-red-400 font-semibold p-1 rounded">Remove Device</div>
            </div>
          </div>
        </div>

        <div v-else class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/tv.svg" alt="tv" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Web</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{computerDevices.length}} Device<span v-if="computerListPlural">s</span></p>
            </div>
          </div>
          <div class="flex flex-col py-8 justify-center content-center gap-y-4 px-12">
            <div class="text-center"><img class="w-20 h-20 inline-block" src="@/assets/svg/empty-box.svg" alt=""></div>
            <p class="smallest text-center">You do not have a Computer device logged in yet.</p>
          </div>
        </div>
        <!-- Computer Side -->

        <!-- Mobile Side -->
        <div v-if="mobileDevices.length > 0" class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/mobile.svg" alt="mobile" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Mobile</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{mobileDevices.length}} Device<span v-if="mobileListPlural">s</span></p>
            </div>
          </div>
          <div v-for="(device, index) in mobileDevices" :key="index" class="flex flex-col gap-y-1 mb-2 border-b border-gray-500 pb-3">
            <div class="tiny font-semibold">{{device.deviceModel}}</div>
            <div class="tiny">
              <span class="inline-block active-gray flex-initial p-1 rounded">
                <div class="flex items-center gap-x-1">
                  <div v-if="device.session.loggedIn" class="w-3 h-3 bg-green-500 rounded-full"></div>
                  <span v-if="device.session.loggedIn">Currently signed in</span>
                  <span v-else>{{processTimestamp(device.session.activityTimestamp)}}</span>
                </div>
              </span>
            </div>
            <div class="tiny">{{device.browser.name}}</div>
            <div class="flex justify-between items-center">
              <!-- <div v-if="device.whitelisted" class="tiny flex-initial bg-green-500 text-white p-1 rounded">Whitelisted</div> -->
              <!-- <div v-else class="tiny flex-initial bg-gray-300 text-gray-700 p-1 rounded">Not Whitelisted</div> -->
              <div class="flex-1"></div>
              <div @click="signOutDevice(device.deviceId)" class="tiny cursor-pointer flex-initial border border-red-400 text-red-400 font-semibold p-1 rounded">Remove Device</div>
            </div>
          </div>
        </div>

        <div v-else class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/mobile.svg" alt="mobile" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Mobile</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{mobileDevices.length}} Device<span v-if="mobileListPlural">s</span></p>
            </div>
          </div>
          <div class="flex flex-col py-8 justify-center content-center gap-y-4 px-12">
            <div class="text-center"><img class="w-20 h-20 inline-block" src="@/assets/svg/empty-box.svg" alt=""></div>
            <p class="smallest text-center">You do not have a Mobile device logged in yet.</p>
          </div>
        </div>
        <!-- Mobile Side -->

        <!-- Terminal Side -->
        <div v-if="terminalDevices.length > 0" class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/mobile.svg" alt="mobile" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Terminals</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{terminalDevices.length}} Device<span v-if="terminalListPlural">s</span></p>
            </div>
          </div>
          <div v-for="(device, index) in terminalDevices" :key="index" class="flex flex-col gap-y-1 mb-2 border-b border-gray-500 pb-3">
            <div class="tiny font-semibold">{{device.deviceModel}}</div>
            <div class="tiny">
              <span class="inline-block active-gray flex-initial p-1 rounded">
                <div class="flex items-center gap-x-1">
                  <div v-if="device.session.loggedIn" class="w-3 h-3 bg-green-500 rounded-full"></div>
                  <span v-if="device.session.loggedIn">Currently signed in</span>
                  <span v-else>{{processTimestamp(device.session.activityTimestamp)}}</span>
                </div>
              </span>
            </div>
            <div class="tiny">{{device.browser.name}}</div>
            <div class="flex justify-between items-center">
              <!-- <div v-if="device.whitelisted" class="tiny flex-initial bg-green-500 text-white p-1 rounded">Whitelisted</div> -->
              <!-- <div v-else class="tiny flex-initial bg-gray-300 text-gray-700 p-1 rounded">Not Whitelisted</div> -->
              <div class="flex-1"></div>
              <div @click="signOutDevice(device.deviceId)" class="tiny cursor-pointer flex-initial border border-red-400 text-red-400 font-semibold p-1 rounded">Remove Device</div>
            </div>
          </div>
        </div>

        <div v-else class="col-span-1 lg:col-span-1 border border-gray-500 rounded-lg p-3">
          <div class="flex pb-3">
            <div
              class="w-16 bg-blue-100 h-16 rounded flex items-center justify-center"
            >
              <img src="@/assets/svg/mobile.svg" alt="mobile" />
            </div>
            <div class="flex-1 px-2 pb-4">
              <h3 class="font-semibold text-sm">Terminals</h3>
              <p class="tiny text-blue-600 font-semibold my-1">{{terminalDevices.length}} Device<span v-if="terminalListPlural">s</span></p>
            </div>
          </div>
          <div class="flex flex-col py-8 justify-center content-center gap-y-4 px-12">
            <div class="text-center"><img class="w-20 h-20 inline-block" src="@/assets/svg/empty-box.svg" alt=""></div>
            <p class="smallest text-center">You do not have a terminal device logged in yet.</p>
          </div>
        </div>
        <!-- Terminal Side -->
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
} from "../../../src/browser-db-config/localStorage";
import { DateTime } from "luxon";
import { eventBus } from "../../../src/eventBus";
import WhiteListingMixin from '../../security-module/WhiteListingMixin';
import WalletToken from "./WalletToken";
export default {
  mixins: [WalletToken, WhiteListingMixin],
  data() {
    return {
      isLoading: false
    };
  },
  created () {
    this.getDeviceList();
  },
  methods: {
    async signOutDevice(deviceId) {
      const res = await this.$store.dispatch("DELETE_DEVICE", deviceId)
      eventBus.$emit("trac-alert", {
          message: res.message,
      });
      await this.getDeviceList();
    },
    processTimestamp(timestamp) {
      return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT);
    },
    async getDeviceList() {
      this.isLoading = true;
      const res = await this.$store.dispatch("DEVICE_LIST");
      if (res.status) {
        this.deviceList = res.data || [];
      } else {
        this.deviceList = [];
      }
      this.isLoading = false;
    }
  },
};
</script>

<style lang="postcss" scoped>
.tiny {
  font-size: 10px;
}
.active-gray {
  background: #e0e0e0;
}
</style>